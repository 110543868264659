import React from 'react'
import styled from 'styled-components'
import Icon from '../components/Icon'
import cryingManIcon from '../../static/crying-man-icon.png'

const NotFoundPage = () => {
    return (
        <TextWrapper>
            <Icon size={60} src={cryingManIcon} description={'Crying Man'}></Icon>

            <Text green>Page not found</Text>
                
            <Text white>Aka</Text>
                
            <Text red>"No Pizza for You"</Text>
            
        </TextWrapper>
        
    )
}

const TextWrapper = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    margin: 2rem auto;
`

const Text = styled.div`
    font-family: 'Chelsea Market', cursive;
    text-align: center;
    width: 100%;
    margin: 1rem auto;
    margin-left: ${props => props.ml && props.ml};
    color: ${props => props.green && props.theme.green};
    color: ${props => props.red && props.theme.red};
    ${props => props.white && `
        color: #fff;
        text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    `};
    font-size: 3rem;
`

export default NotFoundPage
